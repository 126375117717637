import React, { useEffect, useCallback, useState, Component } from 'react';
import styled from '@emotion/styled';
import { Cherryblossom } from 'react-cherryblossom';
import { useHistory, Link } from 'react-router-dom';
import { clearTokens, RegisterWithInfo } from '../Utils/auth';
import ThumbnailImage from '../images/img.jpg';
import { toast } from 'react-hot-toast';
import { Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sidebar from '../Components/sideBar';
import NavHeader from '../Components/navHeader'

import styles from '../css/charge.module.css';
import essential from '../css/essential.module.css';

class BankTransfer extends Component<RouteComponentProps> {
    public state = {redirect:false, success:false};
    render(){
        if(this.state.redirect){
            return(<Redirect to="/auth/login"/>);
        }
        return(
            <div className={essential.root}>
                <div className={styles.wrap}>
                    <NavHeader match={this.props.match}/>
                    <Sidebar match={this.props.match}/>
                    <section className={styles.content}>
                        <div className={styles.announcement}>
                            <div className={styles.announcement_title}>
                                ⚠️ 충전 전에 확인해주세요!
                            </div>
                            <div className={styles.announcement_desc}>
                                <span className={styles.announcement_desc_content}>💳 SEX</span>
                                <div className={styles.announcement_details}>
                                    <span>수수료 </span><span>N%</span><span> | 즉시적립 </span><span>N%</span><span> | 포인트 적립 </span><span>N%</span>
                                </div>
                            </div>
                            <div className={styles.announcement_desc}>
                                <span className={styles.announcement_desc_content}>💵 계좌송금</span>
                                <div className={styles.announcement_details}>
                                    <span>수수료 </span><span>N%</span><span> | 즉시적립 </span><span>N%</span><span> | 포인트 적립 </span><span>N%</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default BankTransfer;