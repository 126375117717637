import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Board from '../pages/board'
import ChargeHub from '../pages/chargehub'
import BankTransfer from '../pages/bank_transfer'

export default withRouter(({ match }) => (
  <>
    <Route path={`${match.path}board`} component={Board} />
    <Route path={`${match.path}charge`} component={ChargeHub} />
    <Route path={`${match.path}bank_transfer`} component={BankTransfer} />
  </>
));