import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { match } from 'react-router';
import styles from '../css/navHeader.module.css'

interface navbarProps {
    match : match;
}

class Sidebar extends Component<navbarProps> {
    render(){
        return( 
            <ul className={styles.nav_header}>
                <div className={styles.nav_logo_holder}>
                    <div className={styles.nav_logo}><strong>Developing</strong></div>
                </div>
                <div className={styles.badge_holder}>
                    <div className={styles.badge}>🔐 보안 연결</div>
                    <div className={styles.badge_desc}>secured by <span>MARUCHE</span></div>
                </div>
            </ul>
        );
    }
}
  

export default Sidebar;