import React, { useEffect, useCallback, useState, Component } from 'react';
import styled from '@emotion/styled';
import { Cherryblossom } from 'react-cherryblossom';
import { useHistory } from 'react-router-dom';
import { clearTokens, RegisterWithInfo } from '../Utils/auth';
import ThumbnailImage from '../images/img.jpg';
import { toast } from 'react-hot-toast';
import { Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sidebar from '../Components/sideBar';
import NavHeader from '../Components/navHeader'

import styles from '../css/main.module.css';
import essential from '../css/essential.module.css';

class Board extends Component<RouteComponentProps> {
    public state = {redirect:false, success:false};
    render(){
        if(this.state.redirect){
            return(<Redirect to="/auth/login"/>);
        }
        return(
            <div className={essential.root}>
                <div className={styles.wrap}>
                <NavHeader match={this.props.match}/>
                    <Sidebar match={this.props.match}/>
                    <section className={styles.content}>
                    <div className={styles.box}>
                        <div className={styles.card}>
                            <div className={styles.title}>📄<span>실시간 구매</span> 현황</div>
                                <div className={styles.desc}><i>●</i><span>실시간 구매 현황입니다.</span></div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.success}>구매완료</em>
                                        <span className={styles.live_log_content}>ad*** 님 - 제품명1 / 1일 구매.</span>
                                    </span>
                                </div>
                            </div>
                        <div className={styles.card}>
                            <div className={styles.title}>📄<span>공지사항</span> 확인</div>
                                <div className={styles.desc}><i>●</i><span>최신 공지사항입니다.</span></div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.green}>해결</em>
                                        <span className={styles.live_log_content}>특정 제품군 윈도우 11 실행 오류.</span>
                                    </span>
                                </div>
                            </div>
                        <div className={styles.card}>
                            <div className={styles.title}>📄<span>실시간 재고</span> 현황</div>
                                <div className={styles.desc}><i>●</i><span>실시간 재고 현황입니다.</span></div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.success}>%%%%</em>
                                        <span className={styles.live_log_content}>%%%%</span>
                                    </span>
                                </div>
                            </div>
                            <div className={styles.card}>
                            <div className={styles.title}>📄<span>구매 후기</span> 확인</div>
                                <div className={styles.desc}><i>●</i><span>실시간 구매 현황입니다.</span></div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.success}>제품명1</em>
                                        <span className={styles.live_log_content}>간단 한줄 후기입니다.</span>
                                    </span>
                                </div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.success}>제품명1</em>
                                        <span className={styles.live_log_content}>간단 한줄 후기입니다.</span>
                                    </span>
                                </div>
                                <div className={styles.contents}>
                                    <span className={styles.contents_card}>
                                        <em className={styles.success}>제품명1</em>
                                        <span className={styles.live_log_content}>간단 한줄 후기입니다.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Board;