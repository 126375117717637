import React, { Component } from 'react'
import { Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import styles from '../css/404.module.css';

class notFound extends Component<RouteComponentProps> {
    public state = {redirect:false, success:false};
    render(){
        return(
            <div className={styles.wrap}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        404 ERR — Page not found.
                    </div>
                    <span className={styles.desc}>다른 길로 오신 것 같아요.</span>
                    <span className={styles.gohome}>이 페이지는 존재하지 않습니다.<Link to="/" className={styles.slider_text}>메인 페이지로</Link></span>
                </div>
            </div>
        )
    }
}

export default notFound;