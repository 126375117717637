import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Auth from './Auth'
import Root from './Root'
import Error from './Error'
import { getAccessToken } from '../Utils/auth';

const needAuth = <PageProps extends {}>(
  Component: React.FC<PageProps>,
) => {
  return (params: PageProps) => {
    try {
      const accessToken = getAccessToken();

      if (!accessToken) throw new Error('Cannot find access token');

      return <Component {...params} />;
    } catch {
      return <Redirect to="/auth/login" />;
    }
  };
};

const Router : React.FC = () =>(
    <BrowserRouter>
      <Switch>
        <Route path='/auth' component={Auth}/>
        <Route path='/' component={Root}/>
        <Route path='/error' component={Error}/>
        <Route />
      </Switch>
    </BrowserRouter>
)

export default Router;