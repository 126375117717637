import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { match } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import CardGiftcardTwoToneIcon from '@material-ui/icons/CardGiftcardTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import LocalMallTwoToneIcon from '@material-ui/icons/LocalMallTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import ListTwoToneIcon from '@material-ui/icons/ListTwoTone';
import styles from '../css/sideBar.module.css'
import profilepic from '../images/profilepic.jpeg';

interface sidebarProps {
    match : match;
}

class Sidebar extends Component<sidebarProps> {
    public MySwal = withReactContent(Swal);
    componentDidMount() {
        const activePath = this.props.match.path.split('/');
        const element = document.getElementById(activePath[activePath.length - 1]);
        element?.classList.add(styles.select);

        const menu = document.getElementById(activePath[1]+"dropdown"); 
        if(menu) menu.style.display='block';
    }

    public toggle(id:string){
        const menu = document.getElementById(id+"dropdown"); 
        if (menu){
            if(menu.style.display === 'block'){
                menu.style.display='none';
            }else{
                menu.style.display='block';
            } 
        }
    }

    render(){
        return( 
            <ul className={styles.sidebar}>
                <div className={styles.information}>
                    <div className={styles.profileholder}>
                        <div className={styles.profilepic}></div>
                    </div>
                    <div className={styles.username}><span>USER</span>님, 환영합니다!</div>
                    <div className={styles.sidebar_card}>
                        <div className={styles.cash}>
                            <span>보유 금액</span>
                            <div className={styles.cash_amount}>69,000 원</div>
                        </div>
                    </div>
                    <div className={styles.slider}>
                        <span className={styles.slider_box}>
                            <Link to="/charge" className={styles.slider_text}>
                            <ListTwoToneIcon className={styles.slider_icon} /> 구매내역
                            </Link>
                        </span>
                        <span className={styles.slider_box}>
                            <Link to="/charge" className={styles.slider_text}>
                            <ListTwoToneIcon className={styles.slider_icon} /> 충전내역
                            </Link>
                        </span>
                    </div>
                </div>
                <div className={styles.sidebox}>
                    <Link to="/board" style={{ textDecoration: 'none' }}>
                        <li className={styles.item} id="board">
                            <span className={styles.text}>
                                <InfoTwoToneIcon className={styles.icon}/> 메인화면
                            </span>
                        </li>
                    </Link>

                    <Link to="/shop" style={{ textDecoration: 'none' }}>
                        <li className={styles.item} id="shop">
                            <span className={styles.text}>
                                <ShoppingCartTwoToneIcon className={styles.icon}/> 구매상점
                            </span>
                        </li>
                    </Link>

                    {/* 충전 드롭다운 */}
                    <li className={styles.dropdownBtn} onClick={()=>{this.toggle("charge")}}>
                        <span className={styles.text}>
                            <LocalAtmTwoToneIcon className={styles.icon}/> 적립금 충전
                            <KeyboardArrowDownTwoToneIcon className={styles.dropdownIcon} />
                        </span>
                    </li>
                    <div id="chargedropdown" className={styles.dropdownContainer}>
                        <Link to="/charge" style={{ textDecoration: 'none' }}>
                            <li className={styles.dropdownItem} id="charge">
                                <span className={styles.text}>
                                    <CardGiftcardTwoToneIcon className={styles.icon}/> 문화상품권 충전
                                </span>
                            </li>
                        </Link>

                        <li className={styles.dropdownItem} onClick={()=>{
                            this.MySwal.fire({
                                icon:'error',
                                title: '실패!',
                                text:'현재 불가능합니다. 자세한 사항은 공지사항을 참고해주세요.'
                            })
                        }}>
                            <span className={styles.text}>
                                <AccountBalanceTwoToneIcon className={styles.icon}/> 계좌이체 충전
                            </span>
                        </li>
                    </div>

                    {/* 마이페이지 드롭다운 */}
                    <li className={styles.dropdownBtn} onClick={()=>{this.toggle("user")}}>
                        <span className={styles.text}>
                            <AccountBoxTwoToneIcon className={styles.icon}/> 마이페이지
                            <KeyboardArrowDownTwoToneIcon className={styles.dropdownIcon} />
                        </span>
                    </li>
                    <div id="userdropdown" className={styles.dropdownContainer}>
                        <Link to="/user/passwd" style={{ textDecoration: 'none' }}>
                            <li className={styles.dropdownItem} id="charge">
                                <span className={styles.text}>
                                    <LockTwoToneIcon className={styles.icon}/> 비밀번호 변경
                                </span>
                            </li>
                        </Link>
                        <Link to="/user/buylog" style={{ textDecoration: 'none' }}>
                            <li className={styles.dropdownItem} id="buylog">
                                <span className={styles.text}>
                                    <LocalMallTwoToneIcon className={styles.icon}/> 구매기록
                                </span>
                            </li>
                        </Link>
                        <Link to="/user/chargelog" style={{ textDecoration: 'none' }}>
                            <li className={styles.dropdownItem} id="chargelog">
                                <span className={styles.text}>
                                    <ReceiptTwoToneIcon className={styles.icon}/> 충전기록
                                </span>
                            </li>
                        </Link>
                    </div>

                    <Link to="/auth/logout" style={{ textDecoration: 'none' }}>
                        <li className={styles.item}>
                            <span className={styles.text}>
                                <ExitToAppTwoToneIcon className={styles.icon}/> 로그아웃
                            </span>
                        </li>
                    </Link>
                </div>
            </ul>
        );
    }
}
  

export default Sidebar;